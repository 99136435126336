import React from "react";
import { useTranslation } from "react-i18next";

import {  Popover,  PopoverBody } from "reactstrap"
import { ErrorBoundary } from "@elastic/react-search-ui";
import { Vinyl, FilePerson, FileText, GeoAlt, Tag ,People, CalendarCheck} from 'react-bootstrap-icons';
import Harvard  from "./Harvard.js";

export function bestMatchingLang(data, la) {
	if (data === undefined) return la;
	const langCntryCodes = Object.keys(data);
	if (langCntryCodes.includes(la)) return la;
	if (langCntryCodes.includes("de")) return "de";
	if (langCntryCodes.includes("en")) return "en";
	if (langCntryCodes.includes("nn")) return "nn";
	if (langCntryCodes.includes("he")) return "he";
	return langCntryCodes[0];
}

export function multiValuesInline(values) {
	return values.join(" - ")
}
export function persons(values, label,key) {
	if (values === undefined) return <div />
	if (values.length === 0) return <div />
	const r = [];
	console.info(values	)
	values.filter(p=>p.fullName!==undefined).map(p => p.fullName ? p.fullName + " (" + p.role + ")" : "" + " (" + p.role + ")").map((z,index) => r.push(<span key={index}>  {z}  <br/>	</span>));

	console.info(r)
	if(r.length===0)return <></>;
	return <div key={"re"+key} className="sui-result__details"> <h5><FilePerson />{label}</h5> {r}</div >;
}

export function ethnicGroups(values, label,key) {
	if (values === undefined) return <div />
	if (values.length === 0) return <div />
	const r = [];
	values.filter(p=>p.ethnicGroup!==undefined).map(p => p.ethnicGroup).map((z,index) => r.push(<span key={index}>  {z}  <br/>	</span>));
	if(r.length===0)return <></>;
	return  <div key={"re"+key} className="sui-result__details"> <h5><People />{label}</h5> {r}</div >;
}

export function dateSpan(valueStart,valueEnd, label,key) {
	if (valueStart === undefined) return <div />
	if (valueStart.length === 0) return <div />
	return <div key={"re"+key} className="sui-result__details"> <h5><CalendarCheck />{label}</h5> {valueStart} {valueEnd&&valueEnd!==valueStart? " - "+valueEnd:""}</div >;
}
export function listgroup(values, label, icon, separator) {
	if (values === undefined || values === '' || values.length <= 0) return <div />
	return <div className="sui-result__details"> <h5> {icon}{label}</h5>{values.map((p,index)=> <span key={index}>{p}{separator? separator: '\u00A0'}	</span>)}</div >
}
export function langHandler(container, lang, keyPrefix, maxLen) {
	if (container === undefined) {
		return [];
	}
	if (keyPrefix === undefined) {
		keyPrefix = "k";
	}
	let preferredLang = bestMatchingLang(container, lang)

	keyPrefix = keyPrefix + "-" + preferredLang;
	const langCntryCodes = Object.keys(container);
	if (container === undefined) {
		return undefined;
	} if (container === null) {
		return [];
	}
	let langBag = container[preferredLang];

	// first fallback any with lang code part matching preferred path
	if (langBag === undefined) {

		const result = langCntryCodes.findIndex((lng) => { return lng.startsWith(preferredLang); }, preferredLang)
		if (result >= 0) {
			const l = langCntryCodes[result];
			langBag = container[l];
			if (maxLen !== undefined) {
				langBag = container[l.substring(0, maxLen)];
			}
		}
	}
	// second fallback to all available languages
	if (langBag === undefined || langBag === "") {
		langBag = [];
		for (let i = 0; i < langCntryCodes.length; i++) {
			if (container[langCntryCodes[i]] !== undefined) {
				if (typeof container[langCntryCodes[i]] === "object") {
					container[langCntryCodes[i]].map((item, index) => {
						if (maxLen !== undefined) {
							langBag.push(<span
								key={keyPrefix + "-" + i + '-' + langCntryCodes[i] + '-' + index}
								className="notPrefferedLang"
								title={"Preferred language " + preferredLang + " not available. Displaying " + langCntryCodes[i]}>
								{container[langCntryCodes[i]][index].substring(0, maxLen)}
							</span>);
						} else {
							langBag.push(<span
								key={keyPrefix + "-" + i + '-' + langCntryCodes[i] + '-' + index}
								className="notPrefferedLang"
								title={"Preferred language " + preferredLang + " not available. Displaying " + langCntryCodes[i]}>
								{container[langCntryCodes[i]][index]}
							</span>);
						}

					});
				}
				else {
					if (maxLen !== undefined) {
						langBag.push(container[langCntryCodes[i]]).substring(0, maxLen);
					} else {
						langBag.push(container[langCntryCodes[i]]);
					}
				}
			}
		}
	}
	if (langBag === undefined) {
		langBag = ["n/a"];
	}
	if (maxLen !== undefined) {
		const langBag2 = [];
		langBag.map(i => {
			langBag2.push(i.substring(0, maxLen));
		})
		return langBag2;
	}
	return langBag;
}



function CustomResultView({ result, onClickLink }) {
	const { i18n } = useTranslation();
	const [popoverOpen, setPopoverOpen] = React.useState(false);
	const pid = result._meta.rawHit._source.id.replaceAll("\\", "").replaceAll("/", "").replaceAll(":", "").replaceAll(".", "");
	return (
		<ErrorBoundary>
			<li className="sui-result" id={"z-"+pid}>
				<div className="sui-result__header">
					<h3><Vinyl color="#016882" />&nbsp;
						<a onClick={onClickLink} href={result._meta.rawHit._source.landingPage ? result._meta.rawHit._source.landingPage: "#" + result._meta.rawHit._source.id}>
							{   multiValuesInline(langHandler(result._meta.rawHit._source.titles.translations), i18n.language, "tit")}
						</a>
					</h3><span style={{color: "white"}} id={pid} >.</span>
						<Popover isOpen={popoverOpen}
							target={pid} toggle=
							{() => { setPopoverOpen(!popoverOpen) }}>
							<PopoverBody><pre >{JSON.stringify(result._meta.rawHit._source, null, 2)}</pre></PopoverBody>
						</Popover>
				</div>
				<div className="sui-result__body">
					<div className="sui-result-container">
						{persons(result._meta.rawHit._source?.actors, i18n.t("facet.actor"), pid)}
						{ethnicGroups(result._meta.rawHit._source?.actors, i18n.t("facet.ethnicGroup"), pid)}
						{dateSpan(result._meta.rawHit._source?.dateOfRecordingStart,result._meta.rawHit._source?.dateOfRecordingEnd, i18n.t("facet.dateOfRecording.both"), pid)}
						{listgroup(result._meta.rawHit._source?.keywords, i18n.t("facet.keyword"), <Tag />)}
						{listgroup(langHandler(result._meta.rawHit._source?.geographicReferences?.translations, i18n.language, "geoc"), i18n.t("facet.geo.selected"), <GeoAlt />)}
						{listgroup(langHandler(result._meta.rawHit._source?.descriptions?.translations, i18n.language, "desc"), i18n.t("facet.descriptions"),  <FileText />, <br/>)}
						{result._meta.rawHit._source?.provider?.startsWith("Harvard")?<Harvard/>:<></>}
					</div>
				</div>


			</li>
		</ErrorBoundary>
	);
}
export default CustomResultView;


