import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import en from '../locales/en/common.json'
import ar from '../locales/ar/common.json'
import format from "./i18n-format";
import {
	defaultLanguage,
	supportedLanguages,
} from "../config/i18n";
i18next
	.use(HttpApi)
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		// lng will override the browser detector if provided
		lng: defaultLanguage,
		resources: {
			en: {
				translation: en
			},
			ar: {
				translation: ar
			}
		},
		supportedLngs: supportedLanguages.map(
			(lang) => lang.code,
		),
		nonExplicitSupportedLngs: true,

		// Good idea to provide a fallback when loading
		// translations from a back-end, to avoid unsuccessful
		// attempts to load default fallbackLng ("dev").
		fallbackLng: ['en', 'fr', 'de', 'ar', 'nn',],

		// Eagerly loaded languages
		// preload: ["en", "ar"],

		// Back-end config
		// backend: {
		//   loadPath: "/locales/{{lng}}/{{ns}}.json",
		// },

		interpolation: {
			// React will escape output values, so we don't need
			// i18next to do it.
			escapeValue: false,
			format,
		},

		// react-i18next config
		// react: {
		//   useSuspense: true,
		// },

		debug: process.env.NODE_ENV === "development",
	});

export default i18next;

export function languageCodeOnly(fullyQualifiedCode) {
	return fullyQualifiedCode.split("-")[0];
}