import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';

import "@elastic/react-search-ui-views/lib/styles/styles.css";

import "./App.css";

import "./fonts.css";
const Layout = () => {

	return (
		<div className="container">
			<Navbar />
			<Outlet />
			<Footer />
		</div>
	);
};

export default Layout;