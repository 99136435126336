import React from "react";
import { withSearch } from "@elastic/react-search-ui";


function ClearFilters({ filters, clearFilters }) {
  return (
    <div className="sui-sorting clr">
      <div className="sui-sorting__label" onClick={() => clearFilters()}>
         {filters.length>=1?'Clear filters':""} 
      </div >
    </div>
  );
}

export default withSearch(({ filters, clearFilters }) => ({
  filters,
  clearFilters
}))(ClearFilters);
