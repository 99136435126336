import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import App from "./App";
import Imprint from "./Imprint";
import Privacy from "./Privacy";
import Layout from "./Layout";
import "./services/i18n";

ReactDOM.render(
	<React.Suspense fallback="Loading...">
	<BrowserRouter>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route path="/privacy" element={<Privacy/>} />
					<Route path="/imprint" element={<Imprint />} />
					<Route path="/" element={<App />} />
				</Route>
			</Routes>
		</BrowserRouter>
	</React.Suspense>, document.getElementById("root"));
