import React from "react";
import { Trans } from "react-i18next";

function Footer() {
	return (
		<div className="airy">
		<div className="row">
			<div className="col-lg">
				<a href='mailto:pasdzierny@bbaw.de'><Trans i18nKey="global.app.footer">
				</Trans></a>
			</div><br/>
		</div></div>
	);
}

export default Footer;