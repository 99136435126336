import React from 'react';
import Obfuscate from 'react-obfuscate';


function Imprint() {
	return (

		<div className="airy">
			<div className="row">
				<h1>Imprint</h1>
				<h6>Published by:</h6>
				<div className="col-lg "><a href="https://agya.info/">The Arab-German Young Academy of Sciences and Humanities (AGYA)</a><br />
					at the <a href="https://www.bbaw.de/">Berlin-Brandenburg Academy of Sciences and Humanities (BBAW)</a><br />
					Matthias Pasdzierny<br />
					Jägerstr. 22–23<br />
					10117 Berlin/Germany
				</div>
				<h6>Represented by:</h6>
				<div className="col-lg">
					Prof. Dr. Dr. h.c. mult. Christoph Markschies<br/>
					Tel.: +49 (0)30 20370-0<br/>
					E-Mail: <Obfuscate email="bbaw@bbaw.de">Contact BBAW</Obfuscate>
				</div>
				<h6>Scientific Curatorship & Webmaster:</h6>
				<div className="col-lg">
					Prof. Dr. Matthias Pasdzierny<br/>
					E-Mail: <Obfuscate email="pasdzierny@bbaw.de">Contact Prof. Dr. Matthias Pasdzierny</Obfuscate>
				</div>
				<h6>Legal status:</h6>
				<div className="col-lg">
					Public law legal entity (Rechtsfähige Körperschaft des öffentlichen Rechts)
				</div><br/><br/><br/>
				<h6>VAT Identification No.:</h6>
				<div className="col-lg">
					DE 167 449 058 (according to §27 a of the Value Added Tax Law of the Federal Republic of Germany)
				</div>
			</div>
		</div>

	);
}

export default Imprint;