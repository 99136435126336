import React from 'react';
import { useTranslation } from "react-i18next";

function Privacy() {
	const { i18n } = useTranslation();
	return (

		<div className="airy">
			<div className="row">

				<h1>Privacy</h1>

				<div className="col-lg"><h6>German</h6>{i18n.t('global.app.privacy.de')}</div>

				<div className="col-lg"><h6>English</h6>{i18n.t('global.app.privacy.en')}</div>
			</div>
		</div>
	);
}

export default Privacy;