import React, { useEffect } from "react";

import ElasticsearchAPIConnector from "@elastic/search-ui-elasticsearch-connector";
import { useTranslation } from "react-i18next";
import CustomResultView from "./CustomResultView"

import {
	ErrorBoundary,
	Facet,
	SearchProvider,
	SearchBox,
	Results,
	PagingInfo,
	ResultsPerPage,
	Paging,
	Sorting,
	WithSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";

import ClearFilters from "./ClearFilters";

import {
	buildFacetConfigFromConfig,
	buildSearchOptionsFromConfig,
	buildSortOptionsFromConfig,
	getConfig,
} from "./config/config-helper";
const { searchKey, endpointBase, engineName } = getConfig();

const connector = new ElasticsearchAPIConnector({
	host: endpointBase,
	index: engineName,
	apiKey: searchKey
});
const manualACQ = {
	results: {
		search_fields: {
			parks_search_as_you_type: {}
		},
		result_fields: {
			title: {
				snippet: {
					size: 100,
					fallback: true
				}
			},
			nps_link: {
				raw: {}
			}
		}
	},
	suggestions: {
		types: {
			documents: {
				fields: ["titles.translations.en.keyword"]
			}
		},
		size: 4
	}
}
const config = {
	searchQuery: {
		facets: buildFacetConfigFromConfig(),
		...buildSearchOptionsFromConfig()
	},
	autocompleteQuery: manualACQ,
	apiConnector: connector,
	alwaysSearchOnInitialLoad: true,
	resultsPerPage: 10,
		initialState: {
			resultsPerPage: 10
	},
	onSearch: (requestState, queryConfig, next) => {
    let updatedState = requestState;
    if (requestState.searchTerm === "" && requestState.sortList.length === 0) {
      updatedState = {
        ...requestState,
        sortList: [{ field: "dateOfRecordingStart.keyword", direction: "asc" }]
      };
    }
    return Promise.resolve(next(updatedState, queryConfig));
  },

};

export default function App() {

	const { i18n } = useTranslation();
	useEffect(() => {
		document.dir = i18n.dir();
		document.documentElement.lang = i18n.language;
		document.title = i18n.t("global.app.name");
		console.info(document.dir);
	}, [i18n, i18n.language]); return (

		<SearchProvider config={config}   >

			<WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
				{({ wasSearched }) => {
					return (
						<ErrorBoundary>
							<Layout
								header={<SearchBox autocompleteSuggestions={false} autocompleteResults={false} shouldClearFilters={false} searchAsYouType={true} />}
								sideContent={
									<div>
										{wasSearched && (
											<Sorting
												label={"Sort by"} 
												sortOptions={buildSortOptionsFromConfig()}
											/>
										)}

										<ClearFilters />
										<Facet
											field="provider.keyword"
											label={i18n.t("facet.provider")}
											show="10"
										/>
										<Facet
											field="actors.fullName.keyword"
											label={i18n.t("facet.actor")}
											filterType="any"
										/>
										<Facet
											field="actors.role.keyword"
											label={i18n.t("facet.role")}
											filterType="any"
										/>
										<Facet
											field="actors.ethnicGroup.keyword"
											label={i18n.t("facet.ethnicGroup")}
											filterType="any"
										/>

										<Facet
											field="keywords.keyword"
											label={i18n.t("facet.keyword")}
											filterType="any"
										/>

										<Facet
											field="cvTerms.cv.keyword"
											label={i18n.t("facet.cv")}
											filterType="any"
										/>
										<Facet
											field="cvTerms.labels.translations.nn.keyword"
											label={i18n.t("facet.cvTerm")}
											filterType="any"
										/>
										<Facet
											field="geographicReferences.translations.de.keyword"
											label={i18n.t("facet.geo.de")}
											filterType="any"
										/><Facet
											field="geographicReferences.translations.en.keyword"
											label={i18n.t("facet.geo.en")}
											filterType="any"
										/><Facet
											field="collections.translations.de.keyword"
											label={i18n.t("facet.collection.de")}
											filterType="any"
										/><Facet
											field="collections.translations.en.keyword"
											label={i18n.t("facet.collection.en")}
											filterType="any"
										/><Facet
											field="collections.translations.nn.keyword"
											label={i18n.t("facet.collection.nn")}
											filterType="any"
										/>
										<Facet
											field="actors.yearOfBirth.keyword"
											label={i18n.t("facet.yearOfBirth")}
										/><Facet
											field="dateOfRecordingStart.keyword"
											label={i18n.t("facet.dateOfRecording.start")}
										/><Facet
											field="dateOfRecordingEnd.keyword"
											label={i18n.t("facet.dateOfRecording.end")}
										/><Facet
											field="inCollectionSince.keyword"
											label={i18n.t("facet.inCollectionSince")}
										/>
										<Facet
											field="mediaFiles.type.keyword"
											label={i18n.t("facet.file.mediaType")}
										/>
										<Facet
											field="mediaFiles.format.keyword"
											label={i18n.t("facet.file.format")}
										/>
										<Facet
											field="actors.gender.keyword"
											label={i18n.t("facet.gender")}
											filterType="any"
										/>
									</div>

								}
								bodyContent={
									<Results resultView={CustomResultView} />
								}
								bodyHeader={
									<React.Fragment>
											{wasSearched && 
											<PagingInfo	mapContextToProps={context => {
				                                  if (context.totalResults<10000) return context;
				                                  return {...context,totalResults: "more than 10000"};
				                                }} />  
											}								
											{wasSearched && <ResultsPerPage options={[5, 10, 50]} />}
									</React.Fragment>
								}
								bodyFooter={<Paging />}
							/>
						</ErrorBoundary>
					);
				}}


			</WithSearch>
		</SearchProvider>
	);
}
