import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

function Harvard(args) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div className="sui-result__details"> 
        See also {' '}
        <a
          href="https://harvardmusiclib.aviaryplatform.com/"
          target="_blank"
          rel="noreferrer"
          id="TooltipExample"
        >
          Harvard Musical Materials
        </a>
        .
      <Tooltip
        {...args}
        isOpen={tooltipOpen}
        target="TooltipExample"
        toggle={toggle}
      >
        Click to access Harvard Musical Materials that might contain the link to the audio file
      </Tooltip>
    </div>
  );
}

Harvard.args = {
  autohide: true,
  flip: true,
};

Harvard.argTypes = {
  placement: {
    control: { type: 'select' },
    options: ['top', 'left', 'right', 'bottom'],
  },
};

export default Harvard;